var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          _vm.helpers.building,
          _vm.getHelperObject('building_type', 'building.building_type_id'),
          _vm.getHelperObject('property_unit', 'building.property_unit_id'),
          'imputation',
          'month',
          _vm.helpers.decimalValue
          //'_order'
        ],"config":{
          name: 'invoice',
          url: 'admin/reports/building_balance_by_concept',
          route: '/admin/reports/building_balance_by_concept',
          display_name: 'Cartera final por concepto',
          params: { _lists: 'months,building_types,property_units,imputations' },
          filters: [
            { key: 'month', multiple: false, default: 'response.data.month_id', forced: true, list: 'months', callback: this.onGetData },
            { key: 'building_code', use: 'building.identifier' },
            { key: 'building_type', config: { select_all: true } },
            { key: 'property_unit', config: { select_all: true } },
            { key: 'imputation', config: { select_all: true } }
          ],
          noCreate: true,
          noOptions: 'hide',
          parseItems: _vm.parseItems
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }