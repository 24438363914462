<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            helpers.building,
            getHelperObject('building_type', 'building.building_type_id'),
            getHelperObject('property_unit', 'building.property_unit_id'),
            'imputation',
            'month',
            helpers.decimalValue
            //'_order'
          ]"
          :config="{
            name: 'invoice',
            url: 'admin/reports/building_balance_by_concept',
            route: '/admin/reports/building_balance_by_concept',
            display_name: 'Cartera final por concepto',
            params: { _lists: 'months,building_types,property_units,imputations' },
            filters: [
              { key: 'month', multiple: false, default: 'response.data.month_id', forced: true, list: 'months', callback: this.onGetData },
              { key: 'building_code', use: 'building.identifier' },
              { key: 'building_type', config: { select_all: true } },
              { key: 'property_unit', config: { select_all: true } },
              { key: 'imputation', config: { select_all: true } }
            ],
            noCreate: true,
            noOptions: 'hide',
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'BuildingBalanceByConcept',
  mixins: [crudMixin],
  data() {
    return {
      month: {}
    }
  },
  methods: {
    onGetData(month_id) {
      if (month_id) {
        this.month = { month_id, _period_name: this.getPeriodName(month_id) }
        this.$refs.crud_table.getData(false, { month_id }, true)
      }
    },

    parseItems(parent, items) {
      this.$set(this.$refs.crud_table.options, 'file_name', this.property.name + ' - Reporte Cierre Cartera - ' + this.getPeriodName(this.$refs.crud_table.response.data.month_id) + ' ' + this.$moment().format('YYYYMMDD HHmmss'))

      const new_items = []

      for (let item in items) {
        item = items[item]
        let added = false
        if (item.invoice_details.length) {
          const imputations = {}
          for (let invoice_detail in item.invoice_details) {
            invoice_detail = item.invoice_details[invoice_detail]
            imputations[invoice_detail.imputation_id] = imputations[invoice_detail.imputation_id] || {}
            imputations[invoice_detail.imputation_id][invoice_detail.origin_month_id] = (imputations[invoice_detail.imputation_id][invoice_detail.origin_month_id] || 0) + invoice_detail.value
          }
          for (const imputation_id of Object.keys(imputations)) {
            for (const origin_month_id of Object.keys(imputations[imputation_id])) {
              const value = imputations[imputation_id][origin_month_id]
              if (value) {
                new_items.push({ building: item.building, imputation: this.parsers.readHelper(imputation_id, 'imputations', 'name'), month: this.parsers.periodName(origin_month_id), value })
                added = true
              }
            }
          }
        }
        if (!added) new_items.push({ building: item.building, imputation: '--', month: '--', value: 0 })
      }

      return new_items
    }
  }
}
</script>
